<template>
  <b-nav-item-dropdown
    class="dropdown-cart mr-25 "
    menu-class="dropdown-menu-media"
    right
    id="dropDown-tag-a"
  >
    <template #button-content>
      <feather-icon
        :badge="experiencesCart.length"
        class="text-body"
        icon="ShoppingCartIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">Mi carrito</h4>
        <b-badge pill variant="light-primary">{{experiencesCart.length}} Productos </b-badge>
      </div>
    </li>

    <!-- Cart Items -->
    <vue-perfect-scrollbar
      v-if="experiencesCart.length"
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <b-media
        v-for="item in experiencesCart"
        :key="item.uuid"
        v-b-tooltip.left="item.name"
      >
        <template #aside>
          <b-img
            v-if="item.modelType != 'booking' && item.modelType != 'rates'  "
            :src="imgUrl + item.coverImage"
            @error="imgAlternativo"
            :alt="item.name"
            rounded
            width="62px"
          />
          <b-img
            v-if="item.modelType == 'rates'"
            :src="imgUrlExperience + item.coverImage"
            @error="imgAlternativo"
            :alt="item.name"
            rounded
            width="62px"
          />
          <b-img
            v-if="item.modelType == 'booking'"
            :src="item.img"
            @error="imgAlternativo"
            :alt="item.name"
            rounded
            width="62px"
          />
        </template>
        <feather-icon
          icon="XIcon"
          class="cart-item-remove cursor-pointer"
          @click.stop="deleteProductInCart(item)"
        />
        <div class="media-heading">
          <h6 class="cart-item-title">
            <b-link class="text-body">
              {{ item.detaildisplay }}
            </b-link>
          </h6>
          <small class="cart-item-by">{{ item.categoryName }}</small>
        </div>

        <div v-if="item.modelType!='rates'" class="cart-item-qty ml-1">
          <b-form-spinbutton
            v-model="item.qty"
            min="0"
            size="sm"
            @change="changeQty($event, item)"
            :readonly="item.categoryName == 'Transfer' || item.categoryName == 'Reservas' || item.categoryName == 'ReservasOwners'"
          />
        </div>

        <div v-if="item.modelType=='rates' " class="cart-item-qty ml-1">
          {{item.typeRate +" "+ item.qty }}
        </div>

        <h5 class="cart-item-price w-25">
          {{subTotalProductoQty(item)}}
        </h5>
      </b-media>
    </vue-perfect-scrollbar>
    <!-- Cart Footer -->
    <li
      v-if="experiencesCart.length"
      class="dropdown-menu-footer"
    >

      <div class="d-flex justify-content-between">
        <span class="mb-0">SubTotal:</span>
        <span class="mb-0">{{formatAmount(currencySubTotal)}}</span>
      </div>
      <div class="d-flex justify-content-between mb-1" style="border-bottom: 2px solid; border-color: #bdbdbd">
        <span class="mb-0">Descuento:</span>
        <span class="mb-0">{{formatAmount(currencyTotalDiscount)}}</span>
      </div>
      <div class="d-flex justify-content-between mb-1">
        <h6 class="font-weight-bolder mb-0">Total:</h6>
        <h6 class="font-weight-bolder mb-0">{{currency.code}} {{formatAmount(currencyTotal)}}</h6>
      </div>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        :to="{ name: 'order-summary' }"
      > Resumen Orden
      </b-button>
    </li>

    <p v-if="!experiencesCart.length" class="m-0 p-1 text-center cart-empty"> Tu carrito esta vacío</p>
  </b-nav-item-dropdown>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import { market } from '@/modules/shop/mixins/market'
import { globalCurrencyFormat } from '@/helpers/helpers'

export default {
  mixins: [ market ],
  components: {
    VuePerfectScrollbar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      items: [],
      imgUrl: process.env.VUE_APP_IMG_SRC_API,
      imgUrlExperience: process.env.VUE_APP_IMGEXP_SRC_API,
      imdDefault: require('@/assets/images/default.jpg'),
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
    }
  },
  watch: {
    itemsAmount :{
      deep:true,
      handler(){
        if (this.itemsAmount == 0) {
          this.setInfoClient(null)
          this.clearHeadersCliente()
          this.clearOwnerInfo()
          this.setOwners(null)
          this.setContract("")
        }
      }
    }
  },
  computed: {
    ...mapState('auth',['user']),
    ...mapState('shop',['cart', 'currency']),
    ...mapGetters('shop',['currencyTotal','currencySubTotal', 'currencyTotalDiscount']),
    experiencesCart(){
      return this.cart.items.filter( product => product.show != true )
    },
    itemsAmount(){
      return this.cart.items?.length
    }
  },
  methods: {
    ...mapActions('shop',['calculateTotalWithDiscounts', 'benefitDiscountManager']),
    ...mapMutations('shop',['addProductInCart', 'setInfoClient', 'clearHeadersCliente', 'clearOwnerInfo', 'setOwners', 'setContract', 'setDescount']),
    imgAlternativo( event ){
      event.target.src = this.imdDefault
    },
    subTotalProductoQty(item){
      const subTotal = (item.displayrates.netSale).toFixed(2)
      return globalCurrencyFormat(subTotal)
    },
    async changeQty(qty, item){
      if ( qty == 0 ) this.deleteProductInCart(item)
      if ( qty > 0) {
        const formaPagoPropietario = this.user.fp.find((fp) => fp.isownersPayment === true)
        const formaPagoPropietarioEnCart = this.cart.cliente.paymentList?.some(fpo => fpo.id == formaPagoPropietario?.id)
        if(this.cart.contractId && item?.benefitDiscount && formaPagoPropietarioEnCart) {
          await this.benefitDiscountManager({
            contractId: this.cart.contractId,
            products: this.cart.items.filter(pr=>pr.categoryName != "ReservasOwners" && !!pr.isBenefitDescout),
          })
        }
        await this.calculateTotalWithDiscounts()
        // this.manageAddProductInCart(item, item.categoryName)
      }
    },
    deleteProductInCart( product ){
      this.manageRemoveProductInCart(product)
    },
    formatAmount(value){
      return globalCurrencyFormat(value)
    }
  },
}
</script>

<style lang="scss" scoped>
.dropdown-cart {
  .media {
    .media-aside {
      align-items: center;
    }
  }
}
.dark-layout .header-navbar .navbar-container .nav .dropdown-cart .dropdown-menu.dropdown-menu-media .dropdown-menu-header {
  border-bottom: 1px solid white;
  background-color: #243447;
}
.dark-layout .notification-title {
  color: white;
}
.dark-layout .header-navbar .navbar-container .nav .dropdown-cart .dropdown-menu.dropdown-menu-media .media {
  border-color: white;
}
.dark-layout .header-navbar .navbar-container .nav .dropdown-cart li {
  color: #fff;
  background-color: #243447;
}
//fondo del cart vacío
.dark-layout .cart-empty{
  background-color: #243447;
  color: white;
}
.dark-layout .badge.badge-light-primary {
  color: #ffffff!important;
  background-color: #999999;
}

</style>
